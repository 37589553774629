









































































































import { Component, Vue } from 'vue-property-decorator';
import ScheduleReminderPocketViewModel from
  '@/vue-app/view-models/components/goals-dashboard/create-goal/link-goal/schedule-reminder-pocket-view-model';

@Component({ name: 'ScheduleReminderPocket' })
export default class ScheduleReminderPocket extends Vue {
  step_schedule_reminder_pocket_model = Vue.observable(
    new ScheduleReminderPocketViewModel(this),
  );

  created() {
    this.step_schedule_reminder_pocket_model.initialize();
  }
}
